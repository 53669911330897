import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ThankYou from '../../components/Thank you'

const IndexPage = () => {
    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
          page(id: "cGFnZToyMTk=") {
            title
          }
        }
      }
  `)

  let page_title = initial_data_query.wpgraphql;
  let title = page_title.page.title;
  

  return(
    <div id="thankyou_page">
        <Layout>

             <SEO title={title} /> 
        
            <div className="thankyou_wrapper">
                <ThankYou/>
            </div>
        </Layout>
    </div>
  )
}

export default IndexPage
