import React from 'react'
import mailsent from '../../images/Mail-sent.jpg';

const Thankyou = () => {
    return (
        <div className='thankyou'>
            <div className='inner container_lg'>
                <div className='flex_view middle center'>
                    <div className="col-md-6">
                        <img src={mailsent} className="mailsent" alt="Check Mark"/>
                    </div>
                    <div className="col-md-6">
                        <div className="Thankyou-text">
                            <h3 className='section_title_lg'>Thank You</h3>
                            <hr className='line' />
                            <p className='msg'>We will get back to you shortly...</p>
                            <a href="/"><button className="backbtn" type="button">Back to homepage</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thankyou
